import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from "gatsby"

import "./mobileNav.scss";
import MenuIcon from "../../images/menu.svg"

const MobileNav = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown className="mobile-nav d-block d-sm-none pt-2" isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className="btn btn-secondary" color="#fff">
                <MenuIcon />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem><Link title="Home" to="/">Home</Link></DropdownItem>
                <DropdownItem><Link title="Leistungen" to="/#services">Leistungen</Link></DropdownItem>
                <DropdownItem><Link title="Referenzen" to="/#references">Referenzen</Link></DropdownItem>
                <DropdownItem><Link title="Stellenangebote" to="/#jobs">Stellenangebote</Link></DropdownItem>
                <DropdownItem><Link title="Kontakt" to="/#contact">Kontakt</Link></DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}

export default MobileNav;