import React from 'react'
import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"

import "./nav.scss";

export default () => {
    return (
        <div className="container-fluid bg-primary d-none d-md-inline-block sticky-top">
            <div className="container px-0">
                <nav className="site-nav py-1">
                    <div className="container">
                        <Scrollspy className="col-10 offset-1 d-flex flex-column flex-md-row justify-content-between" items={['home', 'services', 'references', 'jobs', 'contact']} currentClassName="active" componentTag="div">
                            <Link className="py-3" title="Home" to="/">Home</Link>
                            <Link className="py-3" title="Leistungen" to="/#services">Leistungen</Link>
                            <Link className="py-3" title="Referenzen" to="/#references">Referenzen</Link>
                            <Link className="py-3" title="Stellenangebote" to="/#jobs">Stellenangebote</Link>
                            <Link className="py-3" title="Kontakt" to="/#contact">Kontakt</Link>
                        </Scrollspy>
                    </div>
                </nav>
            </div>
        </div>
    )
}