import React from "react"
import { Link } from "gatsby"

import "./footer.scss"
import Logo from "../../images/logo_white.svg"

export default () => {
    return (
        <footer className="container-fluid py-4 bg-blue">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <Link to="/">
                            <Logo height="50" />
                        </Link>
                    </div>
                    <div className="col-md-7 d-flex align-items-center justify-content-end">
                        <Link to="/datenschutz/">Datenschutz</Link>
                        <span className="text-white mx-2">|</span>
                        <Link to="/impressum/">Impressum</Link>
                    </div>
                </div>
            </div>
        </footer>
    )
}