import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import "./header.scss";

import Logo from "../../images/logo.svg"
import Location from "../../images/location.svg"
import Phone from "../../images/phone.svg"
import MobileNav from "../mobilenav/mobileNav";

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        allHeaderYaml {
          nodes {
            headerItem {
              title,
              subTitle
            }
          }
        }
      }
    `
  )

  return (
    <header>
      <div className="container-fluid bg-secondary">
        <div className="container">
          <div className="row py-4">
            <div className="d-block d-sm-none col-3">
            </div>
            <div className="d-none d-sm-block col-md-4 logo-wrapper">
              <Link className="logo" to="/">
                <Logo height="110" />
              </Link>
            </div>
            <div className="d-block d-sm-none col-6">
              <Link className="logo" to="/">
                <Logo height="60" width="100%" />
              </Link>
            </div>
            <div className="d-block d-sm-none col-3">
              <MobileNav />
            </div>
            <div className="col-12 col-md-8 adress-wrapper">
              <div className="row">
                <div className="col-12 col-md-4 header-item">
                  <p className="border-right-1">
                    <strong>
                      {data.allHeaderYaml.nodes[0].headerItem.title}
                    </strong>
                    <br />
                    <span className="font-weight-light">
                      {data.allHeaderYaml.nodes[0].headerItem.subTitle}
                    </span>
                  </p>
                </div>
                <div className="col-12 col-md-4 header-item">
                  <p className="border-right-1">
                    <Location width="16" />
                    <strong>
                      {data.allHeaderYaml.nodes[1].headerItem.title}
                    </strong>
                    <br />
                    <span className="font-weight-light">
                      {data.allHeaderYaml.nodes[1].headerItem.subTitle}
                    </span>
                  </p>
                </div>
                <div className="col-12 col-md-4 header-item">
                  <p>
                    <Phone width="16" />
                    <strong>
                      {data.allHeaderYaml.nodes[2].headerItem.title}
                    </strong>
                    <br />
                    <span className="font-weight-light">
                      {data.allHeaderYaml.nodes[2].headerItem.subTitle}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}